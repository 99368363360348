@use '../base/colors' as *;
@use '../base/font' as *;

$color-primary: $dark-blue;
$color-secondary: $light-blue;
$color-tertiary: $blue-grey;
$color-danger: $red;
$color-btn-delete: $red;
$color-gray: $grey;
$color-light-gray: $light-grey;
$color-medium-gray: $medium-grey;
$color-pink: $pink;
$color-green: $green;
$color-yellow: $yellow;

$text-color-light-gray: $color-light-gray;
$text-color-gray: $grey;
$text-color-active: $green;
$text-color-inactive:$grey;
$text-color-page-title: black;

$disabled-btn-color: $light-grey;
$disabled-text-color: $medium-grey;

$input-field-color:black;
$input-background-color:white;

//== Viewports
$normal: 992px;

//== Header
$header-height: 80px;

//== Button
$btn-lg-height: 54px;
$btn-md-height: 45px;
$btn-sm-height: 35px;

//== Sidebar
$sidebar-width: 292px;
$sidebar-item-width: 287px;

$sidebar-width-sm: 69px;
$sidebar-item-width-sm: 64px;

$sidebar-item-height: 47px;
$sidebar-font-color: $grey;
$sidebar-background-color: $light-grey;
$sidebar-font-color-active: $color-secondary;
$sidebar-title-color: $sidebar-font-color;
$sidebar-circle-color: $color-light-gray;

//== Layout content
$layout-content-background-color: white;

//== Table
$table-background-color: white;
$table-border-radius: 5px;
$mat-chip-border-radius: 20px;

$table-th-background-color: $color-secondary;
$table-th-font-color: white;
$table-th-font-size: $fs-normal;
$table-th-font-weight: $fw-bold;

$table-td-font-color: black;
$table-row-height: 54px;
$table-td-font-size: $fs-small;
$table-td-font-weight: $fw-normal;
