@use "../abstracts/variables" as *;

mat-nav-list.mat-nav-list.mat-list-base {
  .mat-list-item {
    mat-icon.mat-icon {
      width: 25px;
      height: 25px;
    }

    &.active {
      color: $color-secondary;

      mat-icon.mat-icon {
        &.svg-rect {

          &.svg-fill {
            rect {
              fill: $color-secondary;
            }
          }
        }

        &.svg-path {
          &.svg-fill {
            path {
              fill: $color-secondary;
            }
          }

          &.svg-stroke {
            path {
              stroke: $color-secondary;
            }
          }
        }
      }
    }
  }
}