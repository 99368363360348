@use "../abstracts/variables" as *;
@use "../abstracts/mixins" as *;
.container-card {
  display: flex;
  grid-gap: 10px;
  overflow-x: auto;

  .column-card {
    flex: 0 0 340px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: $color-light-gray;
    @include border-radius(15px);
    padding: 15px;

    .type-title {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $color-medium-gray;

      .count-number {
        display: flex;
        width: 25px;
        height: 25px;
        min-width: 25px;
        min-height: 25px;
        align-items: center;
        justify-content: center;
        background: $color-secondary;
        @include border-radius(15px);
        color: #ffffff;
      }
    }
  }
}
