@use 'abstracts/variables' as *;
@use 'abstracts/mixins' as *;

mat-card.mat-card {
  box-shadow: none !important;
  border: 1px solid rgba(0, 104, 121, 0.15);
  @include border-radius(15px);

  &.danger {
    border: 1px solid rgba(186, 27, 27, 0.5);
    .mat-card-content {
      color: $color-danger;
    }
  }
}