@use 'abstracts/variables' as *;
@use 'base/font' as *;

.mat-tab-label,
.mat-tab-link {
    font-size: $fs-normal;
}

.mat-tab-header {
    margin-bottom: 10px;
}

.mat-tab-label.mat-tab-label-active {
    color: $color-secondary;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $color-secondary;
}

.mat-tab-label-container {
    .mat-tab-list {
        mat-ink-bar.mat-ink-bar {
            height: 4px;
        }
    }
}