@use "../abstracts/variables" as *;

mat-tree.mat-tree {
  .mat-tree-node {
    &.active {
      color: $color-secondary;

      mat-icon.mat-icon {
        &.svg-rect {
          &.svg-fill {
            rect {
              fill: $color-secondary;
            }
          }
        }

        &.svg-path {
          &.svg-fill {
            path {
              fill: $color-secondary;
            }
          }

          &.svg-stroke {
            path {
              stroke: $color-secondary;
            }
          }
        }
      }
    }
  }
}
