@use "../abstracts/variables" as *;
@use "../base/font" as *;

.mat-button,
.mat-raised-button,
.mat-flat-button,
.mat-stroked-button {
  &.mat-button-base {
    border-radius: 15px;
  }

  span.mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}

.mat-button,
.mat-raised-button,
.mat-flat-button,
.mat-stroked-button,
.mat-icon-button,
.mat-fab,
.mat-mini-fab {
  &.btn-lg {
    height: $btn-lg-height;
    font-size: $fs-normal;
  }

  &.btn-md {
    height: $btn-md-height;
    font-size: $fs-normal;
  }

  &.btn-sm {
    height: $btn-sm-height;
    font-size: $fs-small;
    line-height: normal;
  }

  mat-icon.mat-icon {
    &.svg-rect {
      &.svg-fill {
        rect {
          fill: $color-secondary;
        }
      }
    }

    &.svg-path {
      &.svg-fill {
        path {
          fill: $color-secondary;
        }
      }

      &.svg-stroke {
        path {
          stroke: $color-secondary;
        }
      }
    }
  }

  &.mat-primary:not(:disabled) {
    mat-icon.mat-icon {
      &.svg-rect {
        &.svg-fill {
          rect {
            fill: #ffffff;
          }
        }
      }

      &.svg-path {
        &.svg-fill {
          path {
            fill: #ffffff;
          }
        }

        &.svg-stroke {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }

  &.mat-button-disabled {
    &.mat-primary {
      background-color: $disabled-btn-color;
      color: $disabled-text-color;

      mat-icon.mat-icon {
        &.svg-rect {
          &.svg-fill {
            rect {
              fill: $disabled-text-color;
            }
          }
        }

        &.svg-path {
          &.svg-fill {
            path {
              fill: $disabled-text-color;
            }
          }

          &.svg-stroke {
            path {
              stroke: $disabled-text-color;
            }
          }
        }
      }
    }

    mat-icon.mat-icon {
      &.svg-rect {
        &.svg-fill {
          rect {
            fill: $disabled-text-color;
          }
        }
      }

      &.svg-path {
        &.svg-fill {
          path {
            fill: $disabled-text-color;
          }
        }

        &.svg-stroke {
          path {
            stroke: $disabled-text-color;
          }
        }
      }
    }
  }
}

.mat-icon-button.btn-round {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 10px;
  border-width: 0;

  &.mat-primary {
    background-color: $color-primary;
    color: white;
  }

  &.mat-button-disabled {
    &.mat-primary {
      background-color: $disabled-btn-color;
      color: $disabled-text-color;

      mat-icon.mat-icon {
        &.svg-rect {
          &.svg-fill {
            rect {
              fill: $disabled-text-color;
            }
          }
        }

        &.svg-path {
          &.svg-fill {
            path {
              fill: $disabled-text-color;
            }
          }

          &.svg-stroke {
            path {
              stroke: $disabled-text-color;
            }
          }
        }
      }
    }

    mat-icon.mat-icon {
      &.svg-rect {
        &.svg-fill {
          rect {
            fill: $disabled-text-color;
          }
        }
      }

      &.svg-path {
        &.svg-fill {
          path {
            fill: $disabled-text-color;
          }
        }

        &.svg-stroke {
          path {
            stroke: $disabled-text-color;
          }
        }
      }
    }
  }
}