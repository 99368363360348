.weather-parameter-content {
    overflow: auto;

    .mat-card {
        min-width: 500px;
    }

    .mat-list-base {
        &.parameter-list {
            position: relative;
            padding-top: 0;

            .mat-list-item {
                height: unset;

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 104, 121, 0.15);
                }
            }

            .mat-list-item-content {
                justify-content: space-between;
                padding: 12px 0;

                .item-end-text {
                    text-align: center;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.50);
                }
            }
        }
    }

    .form-group {
        overflow: hidden;
        display: flex;
        height: 42px;
        padding-right: 0px;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #006879;
        text-align: start;

        &:has(.ng-invalid, .is-invalid) {
            outline: auto;
            outline-color: #ba1b1b;
        }

        .form-control-group {
            display: flex;
            padding: 10px 12px;
            align-items: center;

            .input-inner {
                font: inherit;
                background: transparent;
                border: none;
                outline: none;
                padding: 0;
                margin: 0;
                vertical-align: bottom;
                text-align: inherit;
                -webkit-appearance: none;
                width: 100%;
            }

            .input-mirror {
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                visibility: hidden;
                white-space: nowrap;
                display: inline-block;
                min-width: 2px;
            }

            .input-separator {
                transition: opacity 400ms 133.3333333333ms cubic-bezier(0.25, 0.8, 0.25, 1);
                margin: 0 12px;
            }

            .input-start-wrapper {
                position: relative;
                overflow: hidden;
                max-width: calc((126px / 2) - 4px);
                min-width: 28px;

                .input-inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        .measurement {
            display: flex;
            height: 100%;
            width: 66px;
            padding: 0px 10px;
            align-items: center;
            justify-content: center;
            gap: 12px;
            background: #F1F1F1;
        }
    }

    .invalid-feedback {
        color: #BA1B1B;
        font-size: 75%;
    }
}