@use 'abstracts/variables' as *;

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-drop-list.cdk-drop-list-dragging .drag-drop-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-drop-custom-placeholder {
  background: $color-medium-gray;
  border: dotted 3px $color-gray;
  min-height: 54px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}