@use "./abstracts/variables";
@use "./abstracts/mixins";
@use "./themes/custom-material";
@use "./themes/quill";
@use "./layout/form-group";
@use "./components/table";
@use "./components/dialog";
@use "./components/menu-dialog";
@use "./components/button";
@use "./components/card";
@use "./components/list";
@use "./components/tree";
@use "./components/drag-drop";
@use "./components/tab";
@use "./components/text-truncate";
@use "./base/base";
@use "./base/font";

@use "./pages/dashboard";
@use "./pages/profile";
@use "./pages/login";
@use "./pages/form-create";
@use "./snackbar";
@use "./pages/crop-cycle-detail";
@use "./pages/crop-cycle-template";
@use "./layout/report-detail";

@use "./pages/disaster-warning";
@use "./pages/weather-parameter";


/* quill */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
