.quill-container {
    position: relative;
    margin: 0.45rem 0;
    padding-bottom: 1.34375em;

    quill-editor {
        .ql-toolbar {
            &.ql-snow {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
        }

        .ql-container {
            &.ql-snow {
                overflow: hidden;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }
}