@use 'abstracts/variables' as *;
@use 'base/font' as *;

.mat-menu-panel.option-menu {
    &.profile {
        max-width: 298px;
    }

    padding: 0 17px;
    border-radius: 15px;
    width: 100vw;

    mat-divider {
        margin-bottom: 10px;
    }

    .menu-title {
        padding: 10px 10px 0;
    }

    .mat-menu-item {
        height: 45px;
        border-radius: 15px;
        margin: 10px 0;
        padding: 0 10px;

        &:hover {
            background: rgba(0, 104, 121, 0.05);
        }

        &.menu-profile {
            .m-pr-item {
                display: flex;
                gap: 10px;
                align-items: center;

                .mat-button-wrapper {
                    img.avtar-icon {
                        width: 32px;
                        height: 32px;
                    }
                }

                .m-pr-name {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: $fs-small;
                    width: 100%;
                    overflow-x: hidden;
                    .edit-profile {
                        font-size: $fs-x-small;
                        color: $color-secondary;
                        text-decoration: underline;
                    }
                    .m-pr-full-name {
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    }
                }
            }
        }

        &.menu-logout {
            color: $color-danger;
        }

        &.menu-change-password {
            color: $color-gray;
        }

        .mat-icon {
            color: $color-primary;
        }

        .mat-icon.delete {
            color: $color-danger;
        }
    }
}
