
$dark-blue: #003640; // color-primary
$light-blue: #006879; // color-secondary
$blue-grey: #4A6268;
$green: #3F7627;
$red: #BA1B1B;
$yellow: #D6AD25;
$grey: #979797;
$light-grey: #F6F6F6;
$medium-grey: #DDDDDD;
$pink: #FFDAD4;
$approve: rgba(24, 174, 0, 1);