@use "../abstracts/variables" as *;
@use "../base/font" as *;
@use "../abstracts/mixins" as *;

.login-username {
  width: 100%;
  height: 100%;
  min-width: 451px;
  overflow: hidden;
  position: relative;
  background: url("/assets/svgs/bg-login.svg") no-repeat center center fixed,
    linear-gradient(90deg, #003640 0%, #006879 100%);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .main-form {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    .form-login {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo-form {
        flex: 50%;
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;

        .logo-form-content {
          img {
            width: 40%;
          }
        }
      }

      .content-form {
        flex: 50%;
        height: 100%;
        @include border-radius(0px);
        display: flex;
        justify-content: center;
        align-items: center;

        .content-login {
          width: 451px;
          .sub-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 554px;
            background: rgba(0, 0, 0, 0.1);
            @include border-radius(20px);

            .title-form {
              color: #ffffff;
              margin-bottom: 13px;

              h1 {
                font-weight: $fw-normal;
                text-align: center;
                margin-bottom: 5px;
              }
            }

            form {
              max-width: 550px;
            }
          }
        }

        mat-form-field.mat-form-field.mat-form-field-appearance-outline {
          .mat-form-field-wrapper {
            .mat-form-field-flex {
              .mat-form-field-outline {
                color: #ffffff;
              }

              .mat-form-field-infix {
                input.mat-input-element {
                  color: #ffffff;
                  font-size: $fs-normal;
                  font-weight: $fw-normal;
                  line-height: 2.063;

                  &::placeholder {
                    color: $color-tertiary;
                  }
                }
              }
            }
          }

          &.mat-form-field-invalid.mat-form-field-invalid {
            .mat-form-field-outline-thick {
              color: $color-danger;
            }
          }
        }
      }
    }
  }

  .mat-button-base.btn-login {
    width: 100%;
    border: 1px solid #ffffff;
  }

  //* <-- Media Portrait --/>
  @media only screen and (max-width: 1005px) {
    .form-login {
      justify-content: center !important;
      background: linear-gradient(90deg, #003d4d, #00c996, 100%);
      flex-direction: column !important;

      .content-form {
        background: transparent !important;
        backdrop-filter: none !important;
      }

      .logo-form {
        display: none !important;
      }
    }
  }
}
