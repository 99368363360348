@use "base/colors" as *;

$expansion-radius: 15px;
$expansion-y-spacing: 10px;
$expansion-x-spacing: 12px;

.disaster-list-subtitle {
    font-size: 14px;
    color: $grey;
    font-weight: 400;

    .text-secondary {
        color: $light-blue;
    }
}

.disaster-list-content {
    .mat-accordion {
        .mat-expansion-panel {
            border: 1px solid rgba($color: $light-blue, $alpha: 0.15);

            &:last-of-type {
                border-bottom-right-radius: $expansion-radius;
                border-bottom-left-radius: $expansion-radius;
            }

            &:first-of-type {
                border-top-right-radius: $expansion-radius;
                border-top-left-radius: $expansion-radius;
            }

            .mat-expansion-panel-header-description {

                .panel-description {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    margin-left: auto;
                }

                .group-badge {
                    display: flex;
                    padding: 6px 10px;
                    align-items: center;
                    gap: 8px;
                    border-radius: 1000px;
                    border: 1px solid rgba(0, 104, 121, 0.05);
                    background: rgba(0, 104, 121, 0.05);
                }

                .badge {
                    display: flex;
                    padding: 0px 6px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 20px;
                    border-radius: 24px;
                    background: #BA1B1B;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }

    .mat-expansion-indicator::after {
        color: $light-blue;
    }

    .mat-expansion-panel-spacing {
        margin: 0;
    }

    .mat-expansion-panel-header {
        height: unset;
        padding: $expansion-y-spacing $expansion-x-spacing;

        .mat-expansion-panel-header-title {
            gap: 8px;
            color: $light-blue;

            .ellipse {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: rgba($color: $light-blue, $alpha: 0.15);
                color: rgba($color: $light-blue, $alpha: 0.4);
            }
        }
    }

    .mat-expansion-panel-body {
        padding: 0 $expansion-x-spacing $expansion-y-spacing;

        .disaster-item-container {
            display: flex;
            gap: 15px;
            flex-wrap: nowrap;
            overflow: auto;

            .scale {
                color: $red;
                font-weight: bold;
            }

            .mat-card {
                flex: 0 0 500px;

                .mat-card-subtitle {
                    color: #006879;
                    font-weight: 700;
                    margin-bottom: 12px;
                }
            }

            .alert-item {
                gap: 8px;
            }
        }

        .btn-announcement {
            display: flex;
            margin: auto;
            color: $light-blue;
            background-color: rgba(0, 54, 64, 0.05);
        }
    }
}

.announcement {

    td {
        &.mat-column-picture {
            img {
                object-fit: cover;
                border-radius: 2px;
            }
        }

        &.mat-column-status {
            .status {
                color: rgb(var(--color));
                background-color: rgb(var(--color) / 0.10);
                padding: 0.35em 0.65em;
                font-size: 0.85em;
                border-radius: 50rem;
                text-align: center;

                &.pending {
                    --color: 214 173 37;
                }

                &.approved {
                    --color: 24 174 0;
                }
            }
        }
    }
}

.announcement-operation {
    .noun-drop-down-container {
        position: relative;

        .noun-drop-down {
            max-width: 340px;
            height: 148px;
            cursor: pointer;
            position: relative;

            .remove-icon {
                position: absolute;
                right: -12px;
                top: -12px;
                background-color: #fff;
                border-radius: 50%;
                color: #ba1b1b;
                cursor: pointer;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 12px;
                border: 1px dotted rgba($color: #006879, $alpha: .15);

                &.is-invalid {
                    outline: #ba1b1b auto;
                }
            }
        }
    }

    .ng-submitted {
        quill-editor {
            &.ng-invalid {
                .ql-container {
                    &.ql-snow {
                        outline: #ba1b1b auto;
                    }
                }
            }
        }

        .quill-invalid {
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            margin-top: 0.6666666667em;
            padding: 0 1em;
            font-size: 75%;
        }
    }
}

.announcement-detail {
    .text-grey {
        color: $grey;
    }

    .post-image {
        width: 100%;
        height: 100%;
        max-width: 800px;
        border-radius: 10px;
        border: 1px solid #F6F6F6;
    }
}