@mixin box-shadow($h, $v, $blur, $s, $c) {
  -webkit-box-shadow: $h $v $blur $s $c;
  -moz-box-shadow: $h $v $blur $s $c;
  -ms-box-shadow: $h $v $blur $s $c;
  box-shadow: $h $v $blur $s $c;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin background-image($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-position: center;
}