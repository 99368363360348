@use 'abstracts/variables' as *;

.table {
  border-radius: $table-border-radius;
  background-color: $table-background-color;
  display: flex;
  align-items: center;

  .table-custom {
    overflow-x: auto;
    width: 100%;
    box-shadow: none;

    tr {
      th.mat-header-cell.header-center {
        text-align: center;
      }

      th,
      td {
        height: $table-row-height;
        white-space: nowrap;
        padding: 0 5px;
      }

      th {
        border-bottom: none;
        background-color: $table-th-background-color;
        color: $table-th-font-color;
        font-weight: $table-th-font-weight;
        font-size: $table-th-font-size;

        .mat-sort-header-container {
          justify-content: center;
        }

        &:first-child {
          border-radius: 10px 0 0 0;
        }

        &:last-child {
          border-radius: 0 10px 0 0;
        }
      }

      td.danger {
        color: $color-danger;
      }

      td {
        max-width: 16vw;
        white-space: nowrap;
        color: $table-td-font-color;
        font-weight: $table-td-font-weight;
        font-size: $table-td-font-size;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: none;

        .mat-chip-list-wrapper {
          mat-chip.mat-chip {
            background: rgba(74, 98, 104, 0.1);
            border-radius: $mat-chip-border-radius;
            color: $color-tertiary
          }
        }

        .action-container {
          display: flex;
          justify-content: center;
          gap: 5px;

          button {
            padding: 0px;
            background-color: $color-primary;
            align-items: center;
            display: flex;
            justify-content: center;
            width: 35px;
            height: 35px;
            border-radius: 10px;
            border-width: 0;

            &.mat-button-disabled {
              background-color: $disabled-text-color
            }
          }

          button.btn-delete {
            background: $color-danger;

            &.mat-button-disabled {
              background-color: $disabled-text-color
            }
          }

          button.btn-edit {
            background: $color-yellow;

            &.mat-button-disabled {
              background-color: $disabled-text-color
            }
          }

        }
      }

      &.mat-row:nth-child(even) {
        background-color: #F1F7F7;
      }

      &:last-child {
        padding-bottom: 1em;

        .mat-cell {
          border-bottom: none;
        }
      }
    }
  }
}

.table {
  overflow: auto;
  
  table.kas-custom {
    min-width: var(--min-width, 500px);

    tr {
      th,
      td {
        max-width: 16vw;
        white-space: unset;
      }
    }
  }
}