@use "../base/font" as *;

.cc-dialog-detail {
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    .cc-content-grid {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .c-l {
        // min-width: 110px;
      }

      .c-r {
        flex: 1;
      }
    }
  }

  .description {
    border: 1px solid rgba(74, 98, 104, 0.1);
    border-radius: 10px;
    padding: 15px 20px;
    min-height: 50px;
    margin-bottom: 10px;
  }

  section.section-1 {
    margin-bottom: 20px;
  }
}

.bag-photo {
  height: 280px;
  img {
    height: 260px;
    object-fit: cover;
  }

  margin: 10px 0 20px;
}