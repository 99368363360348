@use 'abstracts/variables' as *;
@use 'abstracts/mixins' as *;
@use 'base/font' as *;

mat-card.mat-card {
  mat-spinner.mat-spinner {
    width: 60px !important;
    height: 60px !important;

    svg {
      width: 60px !important;
      height: 60px !important;
    }
  }

  position: relative;

  mat-spinner.mat-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  &.pie-card {
    h3 {
      margin: 0;
    }

    .pie-info {
      display: flex;
      align-items: center;
      // justify-content: center;

      .pie-info__right {
        flex: 1 1 30%;
        display: flex;
        justify-content: center;
        align-items: center;

        .pie-body {
          width: 150px;
        }
      }

      .pie-info__left {
        flex: 1 1 70%;

        div.dot {
          min-width: 10px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 1px solid rgba(0, 104, 121, 0.1);
        }

        .dt-top {
          margin-bottom: 17px;

          div.dot {
            background-color: $color-secondary;
          }
        }

        .dt-bottom {
          div.dot {
            background-color: $color-light-gray;
          }
        }

        h4.mat-h4 {
          margin: 0;
        }

        .ol {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;

          .listing {
            display: flex;
            align-items: baseline;
            gap: 10px;

            span {
              color: $color-gray;
            }

            .province {
              display: flex;
              gap: 10px;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;

              .more {
                color: $color-secondary;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  &.recent-activity-card {
    .card-content {
      .action-list {
        background: rgba(246, 246, 246, 0.5);
        margin: 10px 0;
        border-radius: 15px;

        padding: 15px 17px;
        display: flex;
        gap: 10px;

        .a-l-info {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;

          .box {
            .box__top {
              font-size: $fs-small;
              margin-bottom: 10px;
            }

            .box__bottom {
              font-size: $fs-x-small;
            }
          }

          .box:last-child {
            font-size: $fs-x-small;
            margin-left: auto;
            align-self: baseline;
            min-width: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

app-harvest-estimation,
app-recent-action-soil,
app-recent-action-farmer,
app-latest-report,
app-crop-harvest-pie,
app-crop-cycle-pie,
app-farmer-pie,
app-soil-pie {
  mat-card.mat-card {
    min-height: 200px;
    height: 100%;
  }

  .no-result {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 100%;
    width: 100%;

    img {
      width: 90px;
      height: 90px;
    }
  }
}

.custom-legend {
  display: flex;
  gap: 5px;
  // align-items: center;
  font-size: $fs-small;
  flex-wrap: wrap;
  line-height: 30px;

  .item {
    display: flex;
    gap: 5px;
    align-items: center;

    .dot {
      min-height: 12px;
      min-width: 12px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
    }
  }

}

.select-year {
  width: 75px;
}

.select-crop {
  min-width: 120px;
}

