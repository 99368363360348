@use 'abstracts/variables' as *;
@use 'base/font' as *;

.pr-wrap {
  display: flex;
  margin-bottom: 37px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 10px;

  button {
    width: 87px;
    height: 87px;
    border: 1px solid $color-secondary;

    img {
      width: 100%;
      height: 100%;
      padding: 4px;
    }
  }

  .pr-name {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: $fs-small;

    label {
      font-size: $fs-x-small;
      color: $color-secondary;
    }

    .change-pic {
      font-size: $fs-small;
      text-decoration: underline;
      cursor: pointer;
    }

    #fileInput {
      display: none;
    }
  }
}
