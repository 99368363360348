$fw-normal: 400;
$fw-bold: 700;

$fs-x-small: 12px;
$fs-small: 14px;
$fs-normal: 16px;
$fs-regular: 18px;
$fs-medium: 20px;
$fs-large: 25px;
$fs-xl: 30px;
