@use "abstracts/variables" as *;
@use "base/font" as *;

.dialog-header {
    display: flex;
    justify-content: space-between;
    padding: 0;
    background: none;
    height: auto;

    mat-icon {
        cursor: pointer;
    }
}

mat-dialog-container.mat-dialog-container {
    border-radius: 15px;
    padding: 35px;

    .mat-h3,
    .mat-h4 {
        margin: 0 0 10px;
    }

    .mat-tab-label,
    .mat-tab-link {
        font-size: $fs-small;
    }

    .mat-dialog-content {
        margin-top: 10px;

        .confirm-img {
            max-width: 300px;
            max-height: 279px;
        }
    }

    .mat-dialog-actions {
        display: flex;
        justify-content: flex-end;

        .btn-save {
            background-color: $color-green;
        }

        button.mat-flat-button,
        button.mat-stroked-button {
            width: 116px;
        }
    }
}

.full-screen-modal {
    width: 100%;
    height: 100%;

    .mat-dialog-container {
        padding: 0;
        border-radius: 0;
    }
}

.large-modal {
    width: 950px;
    overflow-y: auto;

    .mat-dialog-container {
        .mat-dialog-actions {
        }
    }
}

.medium-modal {
    width: 910px;
    overflow-y: auto;

    .mat-dialog-container {
        .mat-dialog-actions {
        }
    }

    &.modal-with-tab {
        .mat-dialog-container {
            .mat-tab-group {
                height: 100%;

                .mat-tab-body-wrapper {
                    height: 100%;
                    min-height: 296px;

                    .form-group {
                        .row {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

.small-modal {
    width: 500px;

    .mat-dialog-container {
        padding: 30px;

        .mat-dialog-content {
            margin-top: 10px;
        }
    }
}
